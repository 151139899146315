import React from 'react';
import { graphql } from 'gatsby';
import Layout3 from '../components/layout4';
// import Styles from "./mdlist.module.css";
import { tablecss, dispanel1, linkdiv } from "./mdlist.module.css";
import { StaticImage } from "gatsby-plugin-image";
import Hanrei from "../containers/hanrei2"
// import Cityset1 from "../citysetting/citytitle10"

const HomePage = (props: any) => {
  const { data } = props;

  return (
    <Layout3>
        <div style={{margin: "30px"}}>
        <h1>データ作成支援ページ（このサイトに登録されている全てのコンテンツ一覧）</h1>
        <p>　</p>
        <p>サイト構築へのご協力ありがとうございます。</p>

        <StaticImage src="../images/geographdata.png" alt="img" />
        <br/>
        <p>　</p>

<hr/>
        <h2>このページの概要</h2>
        <p>サイト内のすべての情報を見ることができます。<br/>上記メタデータをダウンロードすることができます。<br />各種データ作成や、校正作業などに活用いただけます。</p>
        <p>　</p>

        <hr/>

        <h2>このページの使い方</h2>
        <div>
          <div className={dispanel1}>
            <h3>データ更新方法１：一覧表での作業（エクセルにコピペ＞修正＞送信）</h3>
            <p>各表はエクセルに貼り付けることができます。<br />各表のタイトルごとコピーしてエクセルに貼り付けて編集し、事務局宛メールしてください。（その際、変更したセルに着色してください。）<br /><span style={{color: "red"}}>一覧形式でざっとチェック＆修正するときにおすすめです。</span></p>
          </div>
          <div>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/m1PbRqdfS3g" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
          <p>　</p>

        </div>
        <div>
          <div className={dispanel1}>
            <h3>データ更新方法２：ページごとの作業（テキストをダウンロード＞修正＞送信）</h3>
            <p>表の右端から、各ページのテキストファイルをダウンロード可能です。<br />各ページの情報の全てがページごとに格納されています。<br />修正されたら、事務局宛メールしてください。<br /><span style={{color: "red"}}>各ページごとに、じっくり執筆＆確認をするときにおすすめです。</span></p>
          </div>
          <div>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/e9SIAcEz1yg" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>  </div>

        </div>

        <p>　</p>
        <p>　</p>
<hr/>
        <h1>各表へのリンク</h1>
        <h2><a href="#basic">基本情報</a></h2>
        <h2><a href="#sdgs">SDGs</a></h2>
        {/* <h2><a href="#lecture">教科書の目次</a></h2> */}
        <h2><a href="#origin_h">出典（高さ）</a></h2>
        <h2><a href="#origin_c">出典（色）</a></h2>
        <h2><a href="#honbun">本文</a></h2>
        <h2><a href="#tags1">タグ(1-10)</a></h2>
        <h2><a href="#tags2">タグ(11-20)</a></h2>
        <h2><a href="#tags_bunrui">タグチェック(分類)</a></h2>
        <h2><a href="#hanrei">凡例</a></h2>
        <h2><a href="#hanrei_gazou">凡例画像</a></h2>
        <h2><a href="#shikiichi">閾値（都市別）</a></h2>
        <h2><a href="#kokudo_shikiichi">閾値（全国）</a></h2>
        <h2><a href="#kml_file">データファイル作成関係</a></h2>
        <h2><a href="#csv" style={{color:"white"}}>csv加工</a></h2>
        {/* <h2><a href="#all">全体</a></h2> */}
        <p>　</p>

        <hr/>

        <div id="basic" className={linkdiv}></div>
        <h2>基本情報</h2>
        <table className={tablecss}>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th>title_height_name</th>
              <th>title_color_name</th>
              <th>hanrei_height_name</th>
              <th>hanrei_color_name</th>
              <th>year_display</th>
              {/* <th>tags</th> */}
              <th>txt</th>
            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no+"/a2"}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>
                <td>{node.frontmatter.title_height_name}</td>
                <td>{node.frontmatter.title_color_name}</td>
                <td>{node.frontmatter.hanrei_height_name}</td>
                <td>{node.frontmatter.hanrei_color_name}</td>
                <td>{node.frontmatter.year_display}</td>
                {/* <td>{node.frontmatter.tags}</td> */}
                <td><a href={"/blog/"+node.frontmatter.no+"/index.md"} download={node.frontmatter.no+".txt"}>{node.frontmatter.no}.txt</a></td>
              </tr>
            ))}
          </tbody>
        </table>

        <div id="sdgs" className={linkdiv}></div>

        <h2>SDGs</h2>
        <table className={tablecss}>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th>sdgs_no</th>
              <th>title_height_name</th>
              <th>title_color_name</th>
              <th>txt</th>
            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no+"/a2"}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>
                <td>{node.frontmatter.sdgs_no}</td>
                <td>{node.frontmatter.title_height_name}</td>
                <td>{node.frontmatter.title_color_name}</td>
                <td><a href={"/blog/"+node.frontmatter.no+"/index.md"} download={node.frontmatter.no+".txt"}>{node.frontmatter.no}.txt</a></td>
              </tr>
            ))}
          </tbody>
        </table>

{/* 
        <div id="lecture" className={linkdiv}></div>
        <h2>教科書の目次</h2>
        <table className={tablecss}>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kou_tisou_pt</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kou_tisou_chp</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kou_tisou_sec</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kou_tisou_sbsec</th>
              <th>kou_tisou_title</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kou_titan_pt</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kou_titan_chp</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kou_titan_sec</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kou_titan_sbsec</th>
              <th>kou_titan_title</th>
              <th>title_height_name</th>
              <th>title_color_name</th>
              <th>txt</th>
            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no+"/a2"}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>
                <td>{node.frontmatter.kou_tisou_pt}</td>
                <td>{node.frontmatter.kou_tisou_chp}</td>
                <td>{node.frontmatter.kou_tisou_sec}</td>
                <td>{node.frontmatter.kou_tisou_sbsec}</td>
                <td>{node.frontmatter.kou_tisou_title}</td>
                <td>{node.frontmatter.kou_titan_pt}</td>
                <td>{node.frontmatter.kou_titan_chp}</td>
                <td>{node.frontmatter.kou_titan_sec}</td>
                <td>{node.frontmatter.kou_titan_sbsec}</td>
                <td>{node.frontmatter.kou_titan_title}</td>
                <td>{node.frontmatter.title_height_name}</td>
                <td>{node.frontmatter.title_color_name}</td>
                <td><a href={"/blog/"+node.frontmatter.no+"/index.md"} download={node.frontmatter.no+".txt"}>{node.frontmatter.no}.txt</a></td>
              </tr>
            ))}
          </tbody>
        </table> */}




        <div id="origin_h" className={linkdiv}></div>
        <h2>出典（高さ）</h2>
        <table className={tablecss}>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th>title_height_name</th>
              <th>hanrei_height_name</th>
              <th>height_origin</th>
              <th>height_origin_note</th>
              <th style={{width:"100px", wordBreak:"break-all"}}>height_origin_url_name</th>
              <th style={{width:"100px", wordBreak:"break-all"}}>height_origin_url</th>
              <th style={{width:"100px", wordBreak:"break-all"}}>height_origin_url2_name</th>
              <th style={{width:"100px", wordBreak:"break-all"}}>height_origin_url2</th>
              <th>sokuchi</th>
              <th>mesh_size</th>
              <th>year_display</th>
              <th>txt</th>
            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no+"/a2"}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>
                <td>{node.frontmatter.title_height_name}</td>
                <td>{node.frontmatter.hanrei_height_name}</td>
                <td>{node.frontmatter.height_origin}</td>
                <td>{node.frontmatter.height_origin_note}</td>
                <td>{node.frontmatter.height_origin_url_name}</td>
                <td style={{width:"100px", wordBreak:"break-all", fontSize:"xx-small"}}><a href={node.frontmatter.height_origin_url}>{node.frontmatter.height_origin_url}</a></td>
                <td>{node.frontmatter.height_origin_url2_name}</td>
                <td style={{width:"100px", wordBreak:"break-all", fontSize:"xx-small"}}><a href={node.frontmatter.height_origin_url2}>{node.frontmatter.height_origin_url2}</a></td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.sokuchi}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.mesh_size}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.year_display}</td>
                <td><a href={"/blog/"+node.frontmatter.no+"/index.md"} download={node.frontmatter.no+".txt"}>{node.frontmatter.no}.txt</a></td>
              </tr>
            ))}
          </tbody>
        </table>

        <div id="origin_c" className={linkdiv}></div>
        <h2>出典（色）</h2>
        <table className={tablecss}>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th>title_color_name</th>
              <th>hanrei_color_name</th>
              <th>color_origin</th>
              <th>color_origin_note</th>
              <th style={{width:"100px", wordBreak:"break-all"}}>color_origin_url_name</th>
              <th style={{width:"100px", wordBreak:"break-all"}}>color_origin_url</th>
              <th style={{width:"100px", wordBreak:"break-all"}}>color_origin_url2_name</th>
              <th style={{width:"100px", wordBreak:"break-all"}}>color_origin_url2</th>
              <th>sokuchi</th>
              <th>mesh_size</th>
              <th>year_display</th>
              <th>txt</th>
            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no+"/a2"}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>
                <td>{node.frontmatter.title_color_name}</td>
                <td>{node.frontmatter.hanrei_color_name}</td>
                <td>{node.frontmatter.color_origin}</td>
                <td>{node.frontmatter.color_origin_note}</td>
                <td>{node.frontmatter.color_origin_url_name}</td>
                <td style={{width:"100px", wordBreak:"break-all", fontSize:"xx-small"}}>{node.frontmatter.color_origin_url}</td>
                <td>{node.frontmatter.color_origin_url2_name}</td>
                <td style={{width:"100px", wordBreak:"break-all", fontSize:"xx-small"}}>{node.frontmatter.color_origin_url2}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.sokuchi}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.mesh_size}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.year_display}</td>
                <td><a href={"/blog/"+node.frontmatter.no+"/index.md"} download={node.frontmatter.no+".txt"}>{node.frontmatter.no}.txt</a></td>
              </tr>
            ))}
          </tbody>
        </table>


        <div id="honbun" className={linkdiv}></div>
        <h2>本文</h2>
        <table className={tablecss}>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th>honbun</th>
              <th>hosoku</th>
              <th>tags</th>
              <th>txt</th>
            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no+"/a2"}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>
                <td>{node.frontmatter.honbun}</td>
                <td>{node.frontmatter.hosoku}</td>
                <td>{node.frontmatter.tags}}</td>
                <td><a href={"/blog/"+node.frontmatter.no+"/index.md"} download={node.frontmatter.no+".txt"}>{node.frontmatter.no}.txt</a></td>
              </tr>
            ))}
          </tbody>
        </table>



        <div id="tags1" className={linkdiv}></div>
        <h2>タグリスト (1-10)</h2>
        <table className={tablecss}>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th>tags</th>
              <th>tags</th>
              <th>tags</th>
              <th>tags</th>
              <th>tags</th>
              <th>tags</th>
              <th>tags</th>
              <th>tags</th>
              <th>tags</th>
              <th>tags</th>
              <th>txt</th>
            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no+"/a2"}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>

                <td>
                  <a href={"/tags/"+node.frontmatter.tags[0]}>
                    {node.frontmatter.tags[0]}
                  </a>
                </td><td>
                  <a href={"/tags/"+node.frontmatter.tags[1]}>
                    {node.frontmatter.tags[1]}
                  </a>
                </td><td>
                  <a href={"/tags/"+node.frontmatter.tags[2]}>
                    {node.frontmatter.tags[2]}
                  </a>
                </td><td>
                  <a href={"/tags/"+node.frontmatter.tags[3]}>
                    {node.frontmatter.tags[3]}
                  </a>
                </td><td>
                  <a href={"/tags/"+node.frontmatter.tags[4]}>
                    {node.frontmatter.tags[4]}
                  </a>
                </td><td>
                  <a href={"/tags/"+node.frontmatter.tags[5]}>
                    {node.frontmatter.tags[5]}
                  </a>
                </td><td>
                  <a href={"/tags/"+node.frontmatter.tags[6]}>
                    {node.frontmatter.tags[6]}
                  </a>
                </td><td>
                  <a href={"/tags/"+node.frontmatter.tags[7]}>
                    {node.frontmatter.tags[7]}
                  </a>
                </td><td>
                  <a href={"/tags/"+node.frontmatter.tags[8]}>
                    {node.frontmatter.tags[8]}
                  </a>
                </td><td>
                  <a href={"/tags/"+node.frontmatter.tags[9]}>
                    {node.frontmatter.tags[9]}
                  </a>
                </td>
                <td><a href={"/blog/"+node.frontmatter.no+"/index.md"} download={node.frontmatter.no+".txt"}>{node.frontmatter.no}.txt</a></td>
              </tr>
            ))}
          </tbody>
        </table>

        <div id="tags2" className={linkdiv}></div>
        <h2>タグリスト (10-20)</h2>
        <table className={tablecss}>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th>tags</th>
              <th>tags</th>
              <th>tags</th>
              <th>tags</th>
              <th>tags</th>
              <th>tags</th>
              <th>tags</th>
              <th>tags</th>
              <th>tags</th>
              <th>tags</th>
              <th>txt</th>
            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no+"/a2"}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>
                <td>
                  <a href={"/tags/"+node.frontmatter.tags[10]}>
                    {node.frontmatter.tags[10]}
                  </a>
                </td><td>
                  <a href={"/tags/"+node.frontmatter.tags[11]}>
                    {node.frontmatter.tags[11]}
                  </a>
                </td><td>
                  <a href={"/tags/"+node.frontmatter.tags[12]}>
                    {node.frontmatter.tags[12]}
                  </a>
                </td><td>
                  <a href={"/tags/"+node.frontmatter.tags[13]}>
                    {node.frontmatter.tags[13]}
                  </a>
                </td><td>
                  <a href={"/tags/"+node.frontmatter.tags[14]}>
                    {node.frontmatter.tags[14]}
                  </a>
                </td><td>
                  <a href={"/tags/"+node.frontmatter.tags[15]}>
                    {node.frontmatter.tags[15]}
                  </a>
                </td><td>
                  <a href={"/tags/"+node.frontmatter.tags[16]}>
                    {node.frontmatter.tags[16]}
                  </a>
                </td><td>
                  <a href={"/tags/"+node.frontmatter.tags[17]}>
                    {node.frontmatter.tags[17]}
                  </a>
                </td><td>
                  <a href={"/tags/"+node.frontmatter.tags[18]}>
                    {node.frontmatter.tags[18]}
                  </a>
                </td><td>
                  <a href={"/tags/"+node.frontmatter.tags[19]}>
                    {node.frontmatter.tags[19]}
                  </a>
                </td>
                <td><a href={"/blog/"+node.frontmatter.no+"/index.md"} download={node.frontmatter.no+".txt"}>{node.frontmatter.no}.txt</a></td>
              </tr>
            ))}
          </tbody>
        </table>

        <div id="tags_bunrui" className={linkdiv}></div>
        <h2>タグチェック（分類）</h2>
        <table className={tablecss}>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th>sdgs_no</th>
              <th>tags</th>
              <th>txt</th>
            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no+"/a2"}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>
                <td>{node.frontmatter.sdgs_no}</td>
                <td>
                  <a href={"/tags/"+node.frontmatter.tags[0]}>
                    {node.frontmatter.tags[0]}
                  </a>
                </td>
                <td><a href={"/blog/"+node.frontmatter.no+"/index.md"} download={node.frontmatter.no+".txt"}>{node.frontmatter.no}.txt</a></td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* <div id="tags" className={linkdiv}></div>
        <h2>タグチェック（出典）</h2>
        <table className={tablecss}>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th>title_height_name</th>
              <th>tags</th>
              <th>title_color_name</th>
              <th>tags</th>
              <th>height_origin</th>
              <th>tags</th>
              <th>color_origin</th>
              <th>tags</th>
              <th>txt</th>
            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no+"/a2"}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>
                <td>{node.frontmatter.title_height_name}</td>
                <td>{node.frontmatter.tags[3]}</td>
                <td>{node.frontmatter.title_color_name}</td>
                <td>{node.frontmatter.tags[4]}</td>
                <td>{node.frontmatter.height_origin}</td>
                <td>{node.frontmatter.tags[5]}</td>
                <td>{node.frontmatter.color_origin}</td>
                <td>{node.frontmatter.tags[6]}</td>
                <td><a href={"/blog/"+node.frontmatter.no+"/index.md"} download={node.frontmatter.no+".txt"}>{node.frontmatter.no}.txt</a></td>
              </tr>
            ))}
          </tbody>
        </table> */}



        <div id="hanrei" className={linkdiv}></div>
        <h2>凡例</h2>
        <table className={tablecss}>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei_height_name</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei_color_name</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>year_display</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei1_name</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei1_color</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei2_name</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei2_color</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei3_name</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei3_color</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei4_name</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei4_color</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>height_ratio</th>
              <th>txt</th>

            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no+"/a2"}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.hanrei_height_name}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.hanrei_color_name}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.year_display}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.hanrei1_name}</td>
                <td style={{width:"100px", wordBreak:"break-all", backgroundColor:node.frontmatter.hanrei1_color}}>{node.frontmatter.hanrei1_color}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.hanrei2_name}</td>
                <td style={{width:"100px", wordBreak:"break-all", backgroundColor:node.frontmatter.hanrei2_color}}>{node.frontmatter.hanrei2_color}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.hanrei3_name}</td>
                <td style={{width:"100px", wordBreak:"break-all", backgroundColor:node.frontmatter.hanrei3_color}}>{node.frontmatter.hanrei3_color}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.hanrei4_name}</td>
                <td style={{width:"100px", wordBreak:"break-all", backgroundColor:node.frontmatter.hanrei4_color}}>{node.frontmatter.hanrei4_color}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.height_ratio}</td>
                <td><a href={"/blog/"+node.frontmatter.no+"/index.md"} download={node.frontmatter.no+".txt"}>{node.frontmatter.no}.txt</a></td>

              </tr>
            ))}
          </tbody>
        </table>



        <div id="hanrei_gazou" className={linkdiv}></div>
        <h2>凡例画像</h2>
        <table className={tablecss}>
          <thead>
            <tr>
              <th>no</th>
              <th>自動生成（右端のtxtで修正可能）</th>
              <th>手動生成（修正は手動）</th>
              {/* <th>hanrei1_color</th>
              <th>hanrei2_color</th>
              <th>hanrei3_color</th>
              <th>hanrei4_color</th> */}
              <th>txt</th>

            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no+"/a2"}>
                    {node.frontmatter.no}
                  </a>
                </td>

                <td style={{width:"50%"}}>
                <Hanrei
              frontmatter={node.frontmatter}
            />
                </td>

                <td>
                  <a href={"/hanrei/"+node.frontmatter.no+"_"+node.frontmatter.year5+".png"}>
                   <img src= {"/hanrei/"+node.frontmatter.no+"_"+node.frontmatter.year5+".png"} />
                  </a>
                </td>

{/* 
                <td style={{width:"5%", wordBreak:"break-all", backgroundColor:node.frontmatter.hanrei1_color}}>{node.frontmatter.hanrei1_color}</td>
                <td style={{width:"5%", wordBreak:"break-all", backgroundColor:node.frontmatter.hanrei2_color}}>{node.frontmatter.hanrei2_color}</td>
                <td style={{width:"5%", wordBreak:"break-all", backgroundColor:node.frontmatter.hanrei3_color}}>{node.frontmatter.hanrei3_color}</td>
                <td style={{width:"5%", wordBreak:"break-all", backgroundColor:node.frontmatter.hanrei4_color}}>{node.frontmatter.hanrei4_color}</td> */}


                <td><a href={"/blog/"+node.frontmatter.no+"/index.md"} download={node.frontmatter.no+".txt"}>{node.frontmatter.no}.txt</a></td>

              </tr>
            ))}
          </tbody>
        </table>


        <div id="shikiichi" className={linkdiv}></div>
        <h2>閾値（都市別）</h2>
        <table className={tablecss}>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei_height_name</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei_color_name</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>csvmode</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei1_name</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>shikii1</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei2_name</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>shikii2</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei3_name</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>shikii3</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei4_name</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>min</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>height_ratio</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>ratio</th>
              <th>txt</th>

            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no+"/a2"}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.hanrei_height_name}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.hanrei_color_name}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.csvmode}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.hanrei1_name}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.shikii1}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.hanrei2_name}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.shikii2}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.hanrei3_name}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.shikii3}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.hanrei4_name}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.min}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.height_ratio}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.ratio}</td>
                <td><a href={"/blog/"+node.frontmatter.no+"/index.md"} download={node.frontmatter.no+".txt"}>{node.frontmatter.no}.txt</a></td>

              </tr>
            ))}
          </tbody>
        </table>


        <div id="kokudo_shikiichi" className={linkdiv}></div>
        <h2>閾値（全国）</h2>
        <table className={tablecss}>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei_height_name</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>hanrei_color_name</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>csvmode</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kokudo_hanrei1_name</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kokudo_shikii1</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kokudo_hanrei2_name</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kokudo_shikii2</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kokudo_hanrei3_name</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kokudo_shikii3</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kokudo_hanrei4_name</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kokudo_min</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kokudo_height_ratio</th>
              <th style={{width:"5px", wordBreak:"break-all"}}>kokudo_ratio</th>
              <th>txt</th>

            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no+"/a2"}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.hanrei_height_name}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.hanrei_color_name}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.csvmode}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.kokudo_hanrei1_name}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.kokudo_shikii1}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.kokudo_hanrei2_name}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.kokudo_shikii2}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.kokudo_hanrei3_name}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.kokudo_shikii3}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.kokudo_hanrei4_name}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.kokudo_min}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.kokudo_height_ratio}</td>
                <td style={{width:"100px", wordBreak:"break-all"}}>{node.frontmatter.kokudo_ratio}</td>
                <td><a href={"/blog/"+node.frontmatter.no+"/index.md"} download={node.frontmatter.no+".txt"}>{node.frontmatter.no}.txt</a></td>

              </tr>
            ))}
          </tbody>
        </table>

        <div id="kml_file" className={linkdiv}></div>
        <h2>データファイル作成関係</h2>
        <table className={tablecss}>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th>title_height_name</th>
              <th>title_color_name</th>
              <th>kml_file</th>
              <th>year_display</th>
              <th>txt</th>

            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no+"/a2"}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>
                <td>{node.frontmatter.title_height_name}</td>
                <td>{node.frontmatter.title_color_name}</td>
                <td>{node.frontmatter.kml_file}</td>
                <td>{node.frontmatter.year_display}</td>
                <td><a href={"/blog/"+node.frontmatter.no+"/index.md"} download={node.frontmatter.no+".txt"}>{node.frontmatter.no}.txt</a></td>

              </tr>
            ))}
          </tbody>
        </table>


<div id="csv" className={linkdiv}></div>

<div style={{color:"white"}}>
サンプル
<br/>
<a href="/11-6_data.csv" download={"65歳以上人口割合×人口総数_2015.csv"} style={{color:"white"}}>・分割csv_20210420</a>
<br/>
<a href="/csv_sample_20210420.csv" download={"指示csv_20210420.csv"} style={{color:"white"}}>・指示csv_20210420</a>
<br/>

{/* サンプル2:3列csv
<br/>
<a href="/csv_sample_20210420.csv" download={"指示csv_20210420.csv"} style={{color:"white"}}>・指示csv_20210420</a>
<br/>
<a href="/csv_sample_20210420.csv" download={"指示csv_20210420.csv"} style={{color:"white"}}>・指示csv_20210420</a> */}

<br/>
  <div style={{textAlign:"right"}}>
    <a href="https://gg-dev.kashika.net/csvsplit/" style={{color:"white"}}>csv作成ページ</a>
    {/* <a href="https://kashika.net/gg-dev/gg-csv/" style={{color:"white"}}>csv作成ページ</a> */}
  </div>
</div>


{/* 
      <div id="all" className={linkdiv}></div>
        <h2>全体</h2>
        <table className={tablecss}>
          <thead>
            <tr>
              <th>no</th>
              <th>title</th>
              <th>sdgs_no</th>
              <th>sdgs_main</th>
              <th>sdgs_subcat</th>
              <th>kou_tisou_pt</th>
              <th>kou_tisou_chp</th>
              <th>kou_tisou_sec</th>
              <th>kou_tisou_sbsec</th>
              <th>kou_tisou_title</th>
              <th>kou_titan_pt</th>
              <th>kou_titan_chp</th>
              <th>kou_titan_sec</th>
              <th>kou_titan_sbsec</th>
              <th>kou_titan_title</th>
              <th>height_name</th>
              <th>height_origin</th>
              <th>height_origin_note</th>
              <th>height_origin_url_name</th>
              <th>height_origin_url2_name</th>
              <th>height_origin_url</th>
              <th>height_origin_url2</th>
              <th>color_name</th>
              <th>color_origin</th>
              <th>color_origin_note</th>
              <th>color_origin_url_name</th>
              <th>color_origin_url2_name</th>
              <th>color_origin_url</th>
              <th>color_origin_url2</th>
              <th>sokuchi</th>
              <th>mesh_size</th>
              <th>year_display</th>
              <th>honbun</th>
              <th>chyusyaku</th>
              <th>hosoku</th>
              <th>tags</th>
              <th>kml_file</th>
              <th>y202012</th>
              <th>y202101</th>
              <th>y202102</th>
              <th>date</th>
              <th>description</th>
            </tr>
          </thead>
          <tbody>
            {data.allMarkdownRemark.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>
                  <a href={"/"+node.frontmatter.no+"/a2"}>
                    {node.frontmatter.no}
                  </a>
                </td>
                <td>{node.frontmatter.title}</td>
                <td>{node.frontmatter.sdgs_no}</td>
                <td>{node.frontmatter.sdgs_main}</td>
                <td>{node.frontmatter.sdgs_subcat}</td>
                <td>{node.frontmatter.kou_tisou_pt}</td>
                <td>{node.frontmatter.kou_tisou_chp}</td>
                <td>{node.frontmatter.kou_tisou_sec}</td>
                <td>{node.frontmatter.kou_tisou_sbsec}</td>
                <td>{node.frontmatter.kou_tisou_title}</td>
                <td>{node.frontmatter.kou_titan_pt}</td>
                <td>{node.frontmatter.kou_titan_chp}</td>
                <td>{node.frontmatter.kou_titan_sec}</td>
                <td>{node.frontmatter.kou_titan_sbsec}</td>
                <td>{node.frontmatter.kou_titan_title}</td>
                <td>{node.frontmatter.height_name}</td>
                <td>{node.frontmatter.height_origin}</td>
                <td>{node.frontmatter.height_origin_note}</td>
                <td>{node.frontmatter.height_origin_url_name}</td>
                <td>{node.frontmatter.height_origin_url2_name}</td>
                <td>{node.frontmatter.height_origin_url}</td>
                <td>{node.frontmatter.height_origin_url2}</td>
                <td>{node.frontmatter.color_name}</td>
                <td>{node.frontmatter.color_origin}</td>
                <td>{node.frontmatter.color_origin_note}</td>
                <td>{node.frontmatter.color_origin_url_name}</td>
                <td>{node.frontmatter.color_origin_url2_name}</td>
                <td>{node.frontmatter.color_origin_url}</td>
                <td>{node.frontmatter.color_origin_url2}</td>
                <td>{node.frontmatter.sokuchi}</td>
                <td>{node.frontmatter.mesh_size}</td>
                <td>{node.frontmatter.year_display}</td>
                <td>{node.frontmatter.honbun}</td>
                <td>{node.frontmatter.chyusyaku}</td>
                <td>{node.frontmatter.hosoku}</td>
                <td>{node.frontmatter.tags}</td>
                <td>{node.frontmatter.kml_file}</td>
                <td>{node.frontmatter.y202012}</td>
                <td>{node.frontmatter.y202101}</td>
                <td>{node.frontmatter.y202102}</td>
                <td>{node.frontmatter.date}</td>
                <td>{node.frontmatter.description}</td>
              </tr>
            ))}
          </tbody>
        </table> */}

      </div>
    </Layout3>
  );
};

export default HomePage;

interface IndexPageProps {
  data: {
    allMarkdownRemark: {
      edges: [
        {
          node: {
            frontmatter: {
              title: string;
              no: string;
              sdgs_no: string;
              sdgs_main: string;
              sdgs_subcat: string;
              kou_tisou_pt: string;
              kou_tisou_chp: string;
              kou_tisou_sec: string;
              kou_tisou_sbsec: string;
              kou_tisou_title: string;
              kou_titan_pt: string;
              kou_titan_chp: string;
              kou_titan_sec: string;
              kou_titan_sbsec: string;
              kou_titan_title: string;
              height_name: string;
              height_origin: string;
              height_origin_note: string;
              height_origin_url: string;
              height_origin_url_name: string;
              height_origin_url2: string;
              height_origin_url2_name: string;
              color_name: string;
              color_origin: string;
              color_origin_note: string;
              color_origin_url: string;
              color_origin_url2: string;
              color_origin_url_name: string;
              color_origin_url2_name: string;
              sokuchi: string;
              mesh_size: string;
              year_display: string;
              honbun: string;
              chyusyaku: string;
              hosoku: string;
              tags: string;
              kml_file: string;
              y202012: string;
              y202101: string;
              y202102: string;
              date: string;
              description: string;
            }
          }
        }
      ]
    }
  };
}



declare function graphql(x: TemplateStringsArray): any;
export const pageQuery = graphql`
query {
  allMarkdownRemark(sort: {fields: frontmatter___no}) {
    edges {
      node {
        frontmatter {
          title
          no
          sdgs_no
          sdgs_main
          sdgs_subcat
          kou_tisou_pt
          kou_tisou_chp
          kou_tisou_sec
          kou_tisou_sbsec
          kou_tisou_title
          kou_titan_pt
          kou_titan_chp
          kou_titan_sec
          kou_titan_sbsec
          kou_titan_title
          height_origin
          height_origin_note
          height_origin_url_name
          height_origin_url2_name
          height_origin_url
          height_origin_url2
          hanrei_height_name
          hanrei_color_name
          title_height_name
          title_color_name
          color_origin
          color_origin_note
          color_origin_url_name
          color_origin_url2_name
          color_origin_url
          color_origin_url2
          sokuchi
          mesh_size
          year_display
          year1
          year2
          year3
          year4
          year5
          honbun
          chyusyaku
          hosoku
          tags
          kml_file
          y202012
          y202101
          y202102
          date
          description
          hanrei1_name
          hanrei1_color
          hanrei2_name
          hanrei2_color
          hanrei3_name
          hanrei3_color
          hanrei4_name
          hanrei4_color
          height_ratio
          csvmode
          shikii1
          shikii2
          shikii3
          min
          ratio
          kokudo_hanrei1_name
          kokudo_hanrei2_name
          kokudo_hanrei3_name
          kokudo_hanrei4_name
          kokudo_height_ratio
          kokudo_shikii1
          kokudo_shikii2
          kokudo_shikii3
          kokudo_min
          kokudo_ratio
        }
      }
    }
  }
}

`;
